import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = {
  Authorization: `Bearer ${token}`,
};

const baseURL = process.env.VUE_APP_BASE_URL;

export function fetchStaff() {
  return axios.get(`${baseURL}/staff/profile`, {
    headers: header,
  });
}

export function fetchAllStaff() {
  return axios.get(`${baseURL}/staff`, {
    headers: header,
  });
}

export function fetchStaffByBranch(branch_id) {
  return axios.get(`${baseURL}/staff?branch_id=${branch_id}`, {
    headers: header,
  });
}

export function fetchStaffByBranchAndPage(branch_id, pageNumber) {
  return axios.get(
    `${baseURL}/staff?page_number=15&branch_id=${branch_id}&page=${pageNumber}`,
    {
      headers: header,
    }
  );
}

export function createStaff(payload) {
  return axios.post(
    `${baseURL}/staff/create`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function getRoles() {
  return axios.get(`${baseURL}/staff/rolelist`, { headers: header });
}
