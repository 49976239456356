import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };

const baseURL = process.env.VUE_APP_BASE_URL;

export function fetchBranchSettings() {
  return axios.get(`${baseURL}/settings/show`, {
    headers: header,
  });
}

export function createSettings(payload) {
  return axios.post(
    `${baseURL}/settings/create`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function editSettings(payload) {
  return axios.post(
    `${baseURL}/settings/edit`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function getNotifications() {
  return axios.get(`${baseURL}/staff/list/notification`, { headers: header });
}

export function markAllAsDone() {
  return axios.get(`${baseURL}/staff/list/notification/markall`, {
    headers: header,
  });
}
export function markAsDone(id) {
  return axios.get(`${baseURL}/staff/list/notification/marksingle?id=${id}`, {
    headers: header,
  });
}
