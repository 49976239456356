<template>
  <div class="py-0 px-8">
    <inventory-dashboard v-if="roles.includes('Inventory Manager')" />
    <executive-dashboard v-else-if="roles.includes('Executive Admin')" />
    <executive-dashboard v-else-if="roles.includes('FrontDesk officier')" />
    <branch-dashboard v-else />
  </div>
</template>

<script>
import { fetchStaff } from "@/services/staff";
import { fetchBranchSettings } from "@/services/settings";

import "@/plugins/apex-charts";

export default {
  name: "Dashboard",
  data() {
    return {
      role: [],
      branches: [],
    };
  },
  components: {
    InventoryDashboard: () => import("@/components/Dashboards/inventory.vue"),
    ExecutiveDashboard: () => import("@/components/Dashboards/executive.vue"),
    BranchDashboard: () => import("@/components/Dashboards/admin.vue"),
  },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
  },
  methods: {
    async fetchThisStaff() {
      const response = await fetchStaff();
      this.$store.commit(
        "SET_PERMISSIONS",
        response.data.staff.staff_permission
      );
      this.$store.commit("SET_USER_DATA", response.data.staff);
    },
    async fetchAllSettings() {
      try {
        const response = await fetchBranchSettings();
        this.$store.commit("SET_SETTINGS", response.data.settings);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchThisStaff();
    this.fetchAllSettings();
  },
};
</script>
